<template>
  <div class="incomeDetails">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">收益</div>
      <router-link tag="div" to="/withdraw" class="rightBtn">提现</router-link>
    </div>

    <div class="detailMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="detailItem" v-for="item in list" :key="item.id">
          <div class="detailInfo">
            <!-- <ImgDecypt class="avatarImg" src="image/b9/pi/ce/x8/2e1cbc5a05184087a7bf52fa223a1ab0.png" round /> -->
            <div class="detailOpt">
              {{ item.tranType }}
              <!-- <span>解锁了你的帖子</span> -->
            </div>
            <div class="timer">{{ item.createdAt | time }}</div>
          </div>
          <div class="contentBox">
            <div class="detailDesc">{{ item.desc }}</div>
            <div class="gold">+{{ item.actualAmount }}金币</div>
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
// import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { queryIncomeDetails } from '@/api/mine';
export default {
  name: 'IncomeDetails',
  components: {
    // ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryIncomeDetails, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.incomeDetails {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 40px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 40px;
      text-align: center;
    }
  }

  .detailMain {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 7px;
    .detailItem {
      // height: 106px;
      background-color: #fff;
      margin: 5px 0;
      padding: 12px 12px;
      box-sizing: border-box;
      .detailInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .avatarImg {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
          /deep/ .laz_com {
            background-color: unset;
            .vanImage {
              background-color: unset;
            }
          }
        }
        .detailOpt {
          flex: 2;
          margin: 0 10px;
          font-size: 16px;
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            color: rgb(153, 153, 153);
            margin-left: 3px;
          }
        }

        .timer {
          font-size: 12px;
          color: rgb(153, 153, 153);
        }
      }

      .contentBox {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

        .detailDesc {
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 12px;
        }

        .gold {
          display: flex;
          align-items: center;
          color: rgb(244, 106, 62);
          font-size: 18px;
        }
      }
    }
  }
}
</style>
